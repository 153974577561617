/**
 * THIS FILE IS AUTOGENERATED
 * Do not edit directly as it will be overwritten by the task that generated it
 */

import type { BrandThemeIconMap } from '@jotta/types/Brand'
import SvgAdd from './../../base/icons/add.svg'
import SvgAddFile from './generated/AddFile.svg'
import SvgAddFolder from './generated/AddFolder.svg'
import SvgAddPhoto from './add-photo.svg'
import SvgAlbum from './../../base/icons/album.svg'
import SvgAlbumAdd from './../../base/icons/album-add.svg'
import SvgAndroid from './../../base/icons/android.svg'
import SvgApple from './generated/Apple.svg'
import SvgAppleLogo from './../../base/icons/apple-logo.svg'
import SvgArchive from './generated/Archive.svg'
import SvgArrowClockwise from './generated/ArrowClockwise.svg'
import SvgArrowCounterClockwise from './generated/ArrowCounterClockwise.svg'
import SvgArrowDown from './generated/ArrowDown.svg'
import SvgArrowLeft from './generated/ArrowLeft.svg'
import SvgArrowRight from './generated/ArrowRight.svg'
import SvgArrowUp from './generated/ArrowUp.svg'
import SvgArrowsDownUp from './generated/ArrowsDownUp.svg'
import SvgAttachment from './generated/Attachment.svg'
import SvgBackup from './generated/Backup.svg'
import SvgBell from './generated/Bell.svg'
import SvgBellAlert from './../../base/icons/bell_alert.svg'
import SvgBellRinging from './generated/BellRinging.svg'
import SvgBiz from './../../base/icons/biz.svg'
import SvgBoldAddFile from './generated/BoldAddFile.svg'
import SvgBoldAddFolder from './generated/BoldAddFolder.svg'
import SvgBoldArchive from './generated/BoldArchive.svg'
import SvgBoldArrowClockwise from './generated/BoldArrowClockwise.svg'
import SvgBoldArrowCounterClockwise from './generated/BoldArrowCounterClockwise.svg'
import SvgBoldArrowDown from './generated/BoldArrowDown.svg'
import SvgBoldArrowLeft from './generated/BoldArrowLeft.svg'
import SvgBoldArrowRight from './generated/BoldArrowRight.svg'
import SvgBoldArrowUp from './generated/BoldArrowUp.svg'
import SvgBoldArrowsDownUp from './generated/BoldArrowsDownUp.svg'
import SvgBoldAttachment from './generated/BoldAttachment.svg'
import SvgBoldBackup from './generated/BoldBackup.svg'
import SvgBoldBell from './generated/BoldBell.svg'
import SvgBoldBellRinging from './generated/BoldBellRinging.svg'
import SvgBoldCamera from './generated/BoldCamera.svg'
import SvgBoldCaretCircleDown from './generated/BoldCaretCircleDown.svg'
import SvgBoldCaretCircleLeft from './generated/BoldCaretCircleLeft.svg'
import SvgBoldCaretCircleRight from './generated/BoldCaretCircleRight.svg'
import SvgBoldCaretCircleUp from './generated/BoldCaretCircleUp.svg'
import SvgBoldCaretCircleUpDown from './generated/BoldCaretCircleUpDown.svg'
import SvgBoldCaretDown from './generated/BoldCaretDown.svg'
import SvgBoldCaretLeft from './generated/BoldCaretLeft.svg'
import SvgBoldCaretRight from './generated/BoldCaretRight.svg'
import SvgBoldCaretUp from './generated/BoldCaretUp.svg'
import SvgBoldCaretUpDown from './generated/BoldCaretUpDown.svg'
import SvgBoldChatCircleDots from './generated/BoldChatCircleDots.svg'
import SvgBoldCheck from './generated/BoldCheck.svg'
import SvgBoldCheckCircle from './generated/BoldCheckCircle.svg'
import SvgBoldCircle from './generated/BoldCircle.svg'
import SvgBoldCircleNotch from './generated/BoldCircleNotch.svg'
import SvgBoldClock from './generated/BoldClock.svg'
import SvgBoldClockCounterClockwise from './generated/BoldClockCounterClockwise.svg'
import SvgBoldCloud from './generated/BoldCloud.svg'
import SvgBoldCloudCheck from './generated/BoldCloudCheck.svg'
import SvgBoldCloudDownload from './generated/BoldCloudDownload.svg'
import SvgBoldCloudUpload from './generated/BoldCloudUpload.svg'
import SvgBoldCloudWarning from './generated/BoldCloudWarning.svg'
import SvgBoldCopy from './generated/BoldCopy.svg'
import SvgBoldCreditCard from './generated/BoldCreditCard.svg'
import SvgBoldDesktop from './generated/BoldDesktop.svg'
import SvgBoldDevice from './generated/BoldDevice.svg'
import SvgBoldDownloadSimple from './generated/BoldDownloadSimple.svg'
import SvgBoldEdit from './generated/BoldEdit.svg'
import SvgBoldExitFullscreen from './generated/BoldExitFullscreen.svg'
import SvgBoldExpandFullscreen from './generated/BoldExpandFullscreen.svg'
import SvgBoldExternal from './generated/BoldExternal.svg'
import SvgBoldEye from './generated/BoldEye.svg'
import SvgBoldEyeSlash from './generated/BoldEyeSlash.svg'
import SvgBoldFile from './generated/BoldFile.svg'
import SvgBoldFileArchive from './generated/BoldFileArchive.svg'
import SvgBoldFileAudio from './generated/BoldFileAudio.svg'
import SvgBoldFileCode from './generated/BoldFileCode.svg'
import SvgBoldFileCss from './generated/BoldFileCss.svg'
import SvgBoldFileDoc from './generated/BoldFileDoc.svg'
import SvgBoldFileDownload from './generated/BoldFileDownload.svg'
import SvgBoldFileHtml from './generated/BoldFileHtml.svg'
import SvgBoldFileJpg from './generated/BoldFileJpg.svg'
import SvgBoldFilePdf from './generated/BoldFilePdf.svg'
import SvgBoldFilePng from './generated/BoldFilePng.svg'
import SvgBoldFilePpt from './generated/BoldFilePpt.svg'
import SvgBoldFileSvg from './generated/BoldFileSvg.svg'
import SvgBoldFileText from './generated/BoldFileText.svg'
import SvgBoldFileUpload from './generated/BoldFileUpload.svg'
import SvgBoldFileVideo from './generated/BoldFileVideo.svg'
import SvgBoldFileXls from './generated/BoldFileXls.svg'
import SvgBoldFileZip from './generated/BoldFileZip.svg'
import SvgBoldFiles from './generated/BoldFiles.svg'
import SvgBoldFolderOpen from './generated/BoldFolderOpen.svg'
import SvgBoldFolderSimple from './generated/BoldFolderSimple.svg'
import SvgBoldFolders from './generated/BoldFolders.svg'
import SvgBoldFunnel from './generated/BoldFunnel.svg'
import SvgBoldGlobeSimple from './generated/BoldGlobeSimple.svg'
import SvgBoldGrid from './generated/BoldGrid.svg'
import SvgBoldGrid2 from './generated/BoldGrid2.svg'
import SvgBoldHome from './generated/BoldHome.svg'
import SvgBoldImageSquare from './generated/BoldImageSquare.svg'
import SvgBoldInfinity from './generated/BoldInfinity.svg'
import SvgBoldInfo from './generated/BoldInfo.svg'
import SvgBoldLaptop from './generated/BoldLaptop.svg'
import SvgBoldLinkSimple from './generated/BoldLinkSimple.svg'
import SvgBoldList from './generated/BoldList.svg'
import SvgBoldListDashes from './generated/BoldListDashes.svg'
import SvgBoldLockKey from './generated/BoldLockKey.svg'
import SvgBoldMagicWand from './generated/BoldMagicWand.svg'
import SvgBoldMapPin from './generated/BoldMapPin.svg'
import SvgBoldMapPinLine from './generated/BoldMapPinLine.svg'
import SvgBoldMemories from './generated/BoldMemories.svg'
import SvgBoldMinus from './generated/BoldMinus.svg'
import SvgBoldMinusCircle from './generated/BoldMinusCircle.svg'
import SvgBoldMinusSquare from './generated/BoldMinusSquare.svg'
import SvgBoldMobile from './generated/BoldMobile.svg'
import SvgBoldMoonStars from './generated/BoldMoonStars.svg'
import SvgBoldMoreHorisontal from './generated/BoldMoreHorisontal.svg'
import SvgBoldMoreVertical from './generated/BoldMoreVertical.svg'
import SvgBoldPause from './generated/BoldPause.svg'
import SvgBoldPauseCircle from './generated/BoldPauseCircle.svg'
import SvgBoldPc from './generated/BoldPC.svg'
import SvgBoldPhoto from './generated/BoldPhoto.svg'
import SvgBoldPhotos from './generated/BoldPhotos.svg'
import SvgBoldPlay from './generated/BoldPlay.svg'
import SvgBoldPlayCircle from './generated/BoldPlayCircle.svg'
import SvgBoldPlus from './generated/BoldPlus.svg'
import SvgBoldPlusCircle from './generated/BoldPlusCircle.svg'
import SvgBoldPlusSquare from './generated/BoldPlusSquare.svg'
import SvgBoldPrinter from './generated/BoldPrinter.svg'
import SvgBoldQuestion from './generated/BoldQuestion.svg'
import SvgBoldScan from './generated/BoldScan.svg'
import SvgBoldScreencast from './generated/BoldScreencast.svg'
import SvgBoldSearch from './generated/BoldSearch.svg'
import SvgBoldSettings from './generated/BoldSettings.svg'
import SvgBoldShareAndroid from './generated/BoldShareAndroid.svg'
import SvgBoldShareIos from './generated/BoldShareIos.svg'
import SvgBoldSidebar from './generated/BoldSidebar.svg'
import SvgBoldSignOut from './generated/BoldSignOut.svg'
import SvgBoldSliders from './generated/BoldSliders.svg'
import SvgBoldSlidersHorizontal from './generated/BoldSlidersHorizontal.svg'
import SvgBoldSmiley from './generated/BoldSmiley.svg'
import SvgBoldSmileyWink from './generated/BoldSmileyWink.svg'
import SvgBoldSpinnerGap from './generated/BoldSpinnerGap.svg'
import SvgBoldSpreadsheet from './generated/BoldSpreadsheet.svg'
import SvgBoldSquare from './generated/BoldSquare.svg'
import SvgBoldStar from './generated/BoldStar.svg'
import SvgBoldSun from './generated/BoldSun.svg'
import SvgBoldSync from './generated/BoldSync.svg'
import SvgBoldTablet from './generated/BoldTablet.svg'
import SvgBoldTerminalWindow from './generated/BoldTerminalWindow.svg'
import SvgBoldTrash from './generated/BoldTrash.svg'
import SvgBoldUploadSimple from './generated/BoldUploadSimple.svg'
import SvgBoldUser from './generated/BoldUser.svg'
import SvgBoldUserCircle from './generated/BoldUserCircle.svg'
import SvgBoldUsersShared from './generated/BoldUsersShared.svg'
import SvgBoldUsersThree from './generated/BoldUsersThree.svg'
import SvgBoldWarningCircle from './generated/BoldWarningCircle.svg'
import SvgBoldWifiHigh from './generated/BoldWifiHigh.svg'
import SvgBoldWifiSlash from './generated/BoldWifiSlash.svg'
import SvgBoldWindowsRestore from './generated/BoldWindowsRestore.svg'
import SvgBoldX from './generated/BoldX.svg'
import SvgBoldXCircle from './generated/BoldXCircle.svg'
import SvgBoldXSquare from './generated/BoldXSquare.svg'
import SvgBrandElkjop from './generated/BrandElkjop.svg'
import SvgBrandGeneric from './generated/BrandGeneric.svg'
import SvgBrandJottacloud from './generated/BrandJottacloud.svg'
import SvgBrandMediamarkt from './generated/BrandMediamarkt.svg'
import SvgBrandShared from './generated/BrandShared.svg'
import SvgBrandTelia from './generated/BrandTelia.svg'
import SvgBubble from './../../base/icons/bubble.svg'
import SvgBubbleOutline from './../../base/icons/bubble-outline.svg'
import SvgBullhorn from './../../base/icons/bullhorn.svg'
import SvgCamera from './generated/Camera.svg'
import SvgCannotMoveCopyCloud from './../../base/icons/cannot-move-copy-cloud.svg'
import SvgCaretCircleDown from './generated/CaretCircleDown.svg'
import SvgCaretCircleLeft from './generated/CaretCircleLeft.svg'
import SvgCaretCircleRight from './generated/CaretCircleRight.svg'
import SvgCaretCircleUp from './generated/CaretCircleUp.svg'
import SvgCaretCircleUpDown from './generated/CaretCircleUpDown.svg'
import SvgCaretDown from './generated/CaretDown.svg'
import SvgCaretLeft from './generated/CaretLeft.svg'
import SvgCaretRight from './generated/CaretRight.svg'
import SvgCaretUpDown from './generated/CaretUpDown.svg'
import SvgCast from './../../base/icons/cast.svg'
import SvgChatCircleDots from './generated/ChatCircleDots.svg'
import SvgCheck from './generated/Check.svg'
import SvgCheckCircle from './generated/CheckCircle.svg'
import SvgCheckcircleEmpty from './../../base/icons/checkcircle-empty.svg'
import SvgCheckcircleSelected from './../../base/icons/checkcircle-selected.svg'
import SvgCheckmark from './../../base/icons/checkmark.svg'
import SvgCheckmarkIndeterminate from './../../base/icons/checkmark-indeterminate.svg'
import SvgCircle from './generated/Circle.svg'
import SvgCircleNotch from './generated/CircleNotch.svg'
import SvgClock from './generated/Clock.svg'
import SvgClockCounterClockwise from './generated/ClockCounterClockwise.svg'
import SvgClose from './../../base/icons/close.svg'
import SvgCloud from './generated/Cloud.svg'
import SvgCloudArrowDown from './generated/CloudArrowDown.svg'
import SvgCloudArrowUp from './generated/CloudArrowUp.svg'
import SvgCloudCheck from './generated/CloudCheck.svg'
import SvgCloudError from './../../base/icons/cloudError.svg'
import SvgCloudWarning from './generated/CloudWarning.svg'
import SvgContact from './../../base/icons/contact.svg'
import SvgControl from './generated/Control.svg'
import SvgCopy from './generated/Copy.svg'
import SvgCreditCard from './generated/CreditCard.svg'
import SvgDesktop from './generated/Desktop.svg'
import SvgDevice from './generated/Device.svg'
import SvgDownload from './../../base/icons/download.svg'
import SvgDownloadSimple from './generated/DownloadSimple.svg'
import SvgEdit from './generated/Edit.svg'
import SvgEmoji from './../../base/icons/emoji.svg'
import SvgEnvelop from './../../base/icons/envelop.svg'
import SvgEraseSmall from './../../base/icons/erase-small.svg'
import SvgExitFullscreen from './generated/ExitFullscreen.svg'
import SvgExpandFullscreen from './generated/ExpandFullscreen.svg'
import SvgExternal from './generated/External.svg'
import SvgEye from './generated/Eye.svg'
import SvgEyeSlash from './generated/EyeSlash.svg'
import SvgFacebook from './generated/Facebook.svg'
import SvgFacebookFLogo from './../../base/icons/facebook-f-logo.svg'
import SvgFamilySettings from './../../base/icons/family-settings.svg'
import SvgFile from './generated/File.svg'
import SvgFileAdd from './../../base/icons/file-add.svg'
import SvgFileArchive from './generated/FileArchive.svg'
import SvgFileAudio from './generated/FileAudio.svg'
import SvgFileCode from './generated/FileCode.svg'
import SvgFileCss from './generated/FileCss.svg'
import SvgFileDoc from './generated/FileDoc.svg'
import SvgFileDownload from './generated/FileDownload.svg'
import SvgFileHtml from './generated/FileHtml.svg'
import SvgFileJpg from './generated/FileJpg.svg'
import SvgFileMenu from './../../base/icons/file-menu.svg'
import SvgFilePdf from './generated/FilePdf.svg'
import SvgFilePng from './generated/FilePng.svg'
import SvgFilePpt from './generated/FilePpt.svg'
import SvgFileSvg from './generated/FileSvg.svg'
import SvgFileText from './generated/FileText.svg'
import SvgFileUpload from './generated/FileUpload.svg'
import SvgFileVideo from './generated/FileVideo.svg'
import SvgFileXls from './generated/FileXls.svg'
import SvgFileZip from './generated/FileZip.svg'
import SvgFiles from './generated/Files.svg'
import SvgFilesApp from './../../base/icons/files-app.svg'
import SvgFillAddFile from './generated/FillAddFile.svg'
import SvgFillAddFolder from './generated/FillAddFolder.svg'
import SvgFillArchive from './generated/FillArchive.svg'
import SvgFillArrowClockwise from './generated/FillArrowClockwise.svg'
import SvgFillArrowCounterClockwise from './generated/FillArrowCounterClockwise.svg'
import SvgFillArrowDown from './generated/FillArrowDown.svg'
import SvgFillArrowLeft from './generated/FillArrowLeft.svg'
import SvgFillArrowRight from './generated/FillArrowRight.svg'
import SvgFillArrowUp from './generated/FillArrowUp.svg'
import SvgFillArrowsDownUp from './generated/FillArrowsDownUp.svg'
import SvgFillAttachment from './generated/FillAttachment.svg'
import SvgFillBackup from './generated/FillBackup.svg'
import SvgFillBell from './generated/FillBell.svg'
import SvgFillBellRinging from './generated/FillBellRinging.svg'
import SvgFillCamera from './generated/FillCamera.svg'
import SvgFillCaretCircleDown from './generated/FillCaretCircleDown.svg'
import SvgFillCaretCircleLeft from './generated/FillCaretCircleLeft.svg'
import SvgFillCaretCircleRight from './generated/FillCaretCircleRight.svg'
import SvgFillCaretCircleUp from './generated/FillCaretCircleUp.svg'
import SvgFillCaretCircleUpDown from './generated/FillCaretCircleUpDown.svg'
import SvgFillCaretDown from './generated/FillCaretDown.svg'
import SvgFillCaretLeft from './generated/FillCaretLeft.svg'
import SvgFillCaretRight from './generated/FillCaretRight.svg'
import SvgFillCaretUp from './generated/FillCaretUp.svg'
import SvgFillCaretUpDown from './generated/FillCaretUpDown.svg'
import SvgFillChatCircleDots from './generated/FillChatCircleDots.svg'
import SvgFillCheck from './generated/FillCheck.svg'
import SvgFillCheckCircle from './generated/FillCheckCircle.svg'
import SvgFillCircle from './generated/FillCircle.svg'
import SvgFillCircleNotch from './generated/FillCircleNotch.svg'
import SvgFillClock from './generated/FillClock.svg'
import SvgFillClockCounterClockwise from './generated/FillClockCounterClockwise.svg'
import SvgFillCloud from './generated/FillCloud.svg'
import SvgFillCloudCheck from './generated/FillCloudCheck.svg'
import SvgFillCloudDownload from './generated/FillCloudDownload.svg'
import SvgFillCloudUpload from './generated/FillCloudUpload.svg'
import SvgFillCloudWarning from './generated/FillCloudWarning.svg'
import SvgFillCopy from './generated/FillCopy.svg'
import SvgFillCreditCard from './generated/FillCreditCard.svg'
import SvgFillDesktop from './generated/FillDesktop.svg'
import SvgFillDevice from './generated/FillDevice.svg'
import SvgFillDownloadSimple from './generated/FillDownloadSimple.svg'
import SvgFillEdit from './generated/FillEdit.svg'
import SvgFillExitFullscreen from './generated/FillExitFullscreen.svg'
import SvgFillExpandFullscreen from './generated/FillExpandFullscreen.svg'
import SvgFillExternal from './generated/FillExternal.svg'
import SvgFillEye from './generated/FillEye.svg'
import SvgFillEyeSlash from './generated/FillEyeSlash.svg'
import SvgFillFile from './generated/FillFile.svg'
import SvgFillFileArchive from './generated/FillFileArchive.svg'
import SvgFillFileAudio from './generated/FillFileAudio.svg'
import SvgFillFileCode from './generated/FillFileCode.svg'
import SvgFillFileCss from './generated/FillFileCss.svg'
import SvgFillFileDoc from './generated/FillFileDoc.svg'
import SvgFillFileDownload from './generated/FillFileDownload.svg'
import SvgFillFileHtml from './generated/FillFileHtml.svg'
import SvgFillFileJpg from './generated/FillFileJpg.svg'
import SvgFillFilePdf from './generated/FillFilePdf.svg'
import SvgFillFilePng from './generated/FillFilePng.svg'
import SvgFillFilePpt from './generated/FillFilePpt.svg'
import SvgFillFileSvg from './generated/FillFileSvg.svg'
import SvgFillFileText from './generated/FillFileText.svg'
import SvgFillFileUpload from './generated/FillFileUpload.svg'
import SvgFillFileVideo from './generated/FillFileVideo.svg'
import SvgFillFileXls from './generated/FillFileXls.svg'
import SvgFillFileZip from './generated/FillFileZip.svg'
import SvgFillFiles from './generated/FillFiles.svg'
import SvgFillFolderOpen from './generated/FillFolderOpen.svg'
import SvgFillFolderSimple from './generated/FillFolderSimple.svg'
import SvgFillFolders from './generated/FillFolders.svg'
import SvgFillFunnel from './generated/FillFunnel.svg'
import SvgFillGrid from './generated/FillGrid.svg'
import SvgFillGrid2 from './generated/FillGrid2.svg'
import SvgFillHome from './generated/FillHome.svg'
import SvgFillImageSquare from './generated/FillImageSquare.svg'
import SvgFillInfinity from './generated/FillInfinity.svg'
import SvgFillInfo from './generated/FillInfo.svg'
import SvgFillInternetWeb from './generated/FillInternetWeb.svg'
import SvgFillLaptop from './generated/FillLaptop.svg'
import SvgFillLinkSimple from './generated/FillLinkSimple.svg'
import SvgFillListDashes from './generated/FillListDashes.svg'
import SvgFillLockKey from './generated/FillLockKey.svg'
import SvgFillMagicWand from './generated/FillMagicWand.svg'
import SvgFillMapPin from './generated/FillMapPin.svg'
import SvgFillMapPinLine from './generated/FillMapPinLine.svg'
import SvgFillMemories from './generated/FillMemories.svg'
import SvgFillMenu from './generated/FillMenu.svg'
import SvgFillMinus from './generated/FillMinus.svg'
import SvgFillMinusCircle from './generated/FillMinusCircle.svg'
import SvgFillMinusSquare from './generated/FillMinusSquare.svg'
import SvgFillMobile from './generated/FillMobile.svg'
import SvgFillMoonStars from './generated/FillMoonStars.svg'
import SvgFillMoreHorisontal from './generated/FillMoreHorisontal.svg'
import SvgFillMoreVertical from './generated/FillMoreVertical.svg'
import SvgFillNoWifi from './generated/FillNoWifi.svg'
import SvgFillPause from './generated/FillPause.svg'
import SvgFillPauseCircle from './generated/FillPauseCircle.svg'
import SvgFillPc from './generated/FillPC.svg'
import SvgFillPhoto from './generated/FillPhoto.svg'
import SvgFillPhotos from './generated/FillPhotos.svg'
import SvgFillPlay from './generated/FillPlay.svg'
import SvgFillPlayCircle from './generated/FillPlayCircle.svg'
import SvgFillPlus from './generated/FillPlus.svg'
import SvgFillPlusCircle from './generated/FillPlusCircle.svg'
import SvgFillPlusSquare from './generated/FillPlusSquare.svg'
import SvgFillPrinter from './generated/FillPrinter.svg'
import SvgFillQuestion from './generated/FillQuestion.svg'
import SvgFillScan from './generated/FillScan.svg'
import SvgFillScreencast from './generated/FillScreencast.svg'
import SvgFillSearch from './generated/FillSearch.svg'
import SvgFillSettings from './generated/FillSettings.svg'
import SvgFillShareAndroid from './generated/FillShareAndroid.svg'
import SvgFillShareIos from './generated/FillShareIos.svg'
import SvgFillSidebar from './generated/FillSidebar.svg'
import SvgFillSignOut from './generated/FillSignOut.svg'
import SvgFillSliders from './generated/FillSliders.svg'
import SvgFillSlidersHorizontal from './generated/FillSlidersHorizontal.svg'
import SvgFillSmiley from './generated/FillSmiley.svg'
import SvgFillSmileyWink from './generated/FillSmileyWink.svg'
import SvgFillSpinnerGap from './generated/FillSpinnerGap.svg'
import SvgFillSpreadsheet from './generated/FillSpreadsheet.svg'
import SvgFillSquare from './generated/FillSquare.svg'
import SvgFillStar from './generated/FillStar.svg'
import SvgFillSun from './generated/FillSun.svg'
import SvgFillSync from './generated/FillSync.svg'
import SvgFillTablet from './generated/FillTablet.svg'
import SvgFillTerminalWindow from './generated/FillTerminalWindow.svg'
import SvgFillTrash from './generated/FillTrash.svg'
import SvgFillUploadSimple from './generated/FillUploadSimple.svg'
import SvgFillUser from './generated/FillUser.svg'
import SvgFillUserCircle from './generated/FillUserCircle.svg'
import SvgFillUsersShared from './generated/FillUsersShared.svg'
import SvgFillUsersThree from './generated/FillUsersThree.svg'
import SvgFillWarningCircle from './generated/FillWarningCircle.svg'
import SvgFillWifiHigh from './generated/FillWifiHigh.svg'
import SvgFillWindowsRestore from './generated/FillWindowsRestore.svg'
import SvgFillX from './generated/FillX.svg'
import SvgFillXCircle from './generated/FillXCircle.svg'
import SvgFillXSquare from './generated/FillXSquare.svg'
import SvgFlagCa from './../../base/icons/flag-ca.svg'
import SvgFlagDe from './../../base/icons/flag-de.svg'
import SvgFlagDk from './../../base/icons/flag-dk.svg'
import SvgFlagEs from './../../base/icons/flag-es.svg'
import SvgFlagFi from './../../base/icons/flag-fi.svg'
import SvgFlagFr from './../../base/icons/flag-fr.svg'
import SvgFlagGb from './../../base/icons/flag-gb.svg'
import SvgFlagNl from './../../base/icons/flag-nl.svg'
import SvgFlagNo from './../../base/icons/flag-no.svg'
import SvgFlagPl from './../../base/icons/flag-pl.svg'
import SvgFlagPt from './../../base/icons/flag-pt.svg'
import SvgFlagSe from './../../base/icons/flag-se.svg'
import SvgFlagUs from './../../base/icons/flag-us.svg'
import SvgFolder from './../../base/icons/folder.svg'
import SvgFolderLocked from './../../base/icons/folder-locked.svg'
import SvgFolderMove from './../../base/icons/folder-move.svg'
import SvgFolderNew from './../../base/icons/folder-new.svg'
import SvgFolderOpen from './generated/FolderOpen.svg'
import SvgFolderSimple from './generated/FolderSimple.svg'
import SvgFolderSync from './../../base/icons/folder-sync.svg'
import SvgFolderUpload from './../../base/icons/folder-upload.svg'
import SvgFolders from './generated/Folders.svg'
import SvgForwardIos from './../../base/icons/forward-ios.svg'
import SvgFunnel from './generated/Funnel.svg'
import SvgGearWhite from './../../base/icons/gear-white.svg'
import SvgGeneric from './../../base/icons/generic.svg'
import SvgGoogle from './generated/Google.svg'
import SvgGoogleGLogo from './../../base/icons/google-g-logo.svg'
import SvgGrid from './generated/Grid.svg'
import SvgGrid2 from './generated/Grid2.svg'
import SvgHelp from './../../base/icons/help.svg'
import SvgHide from './../../base/icons/hide.svg'
import SvgHome from './generated/Home.svg'
import SvgIcCamera from './../../base/icons/ic-camera.svg'
import SvgImageSquare from './generated/ImageSquare.svg'
import SvgInfinity from './generated/Infinity.svg'
import SvgInfo from './generated/Info.svg'
import SvgInstall from './../../base/icons/install.svg'
import SvgInternetWeb from './generated/InternetWeb.svg'
import SvgLaptop from './generated/Laptop.svg'
import SvgLink from './../../base/icons/link.svg'
import SvgLinkSimple from './generated/LinkSimple.svg'
import SvgLinux from './../../base/icons/linux.svg'
import SvgList from './../../base/icons/list.svg'
import SvgListDashes from './generated/ListDashes.svg'
import SvgLockKey from './generated/LockKey.svg'
import SvgLogoFavIcon from './generated/LogoFavIcon.svg'
import SvgLogoHeader from './generated/LogoHeader.svg'
import SvgLogoIcon from './generated/LogoIcon.svg'
import SvgLogoMain from './generated/LogoMain.svg'
import SvgLogout from './../../base/icons/logout.svg'
import SvgMagicWand from './generated/MagicWand.svg'
import SvgMapPin from './generated/MapPin.svg'
import SvgMapPinLine from './generated/MapPinLine.svg'
import SvgMastercard from './generated/Mastercard.svg'
import SvgMemories from './generated/Memories.svg'
import SvgMenu from './generated/Menu.svg'
import SvgMessage from './../../base/icons/message.svg'
import SvgMinus from './generated/Minus.svg'
import SvgMinusCircle from './generated/MinusCircle.svg'
import SvgMinusSquare from './generated/MinusSquare.svg'
import SvgMobile from './generated/Mobile.svg'
import SvgMoonStars from './generated/MoonStars.svg'
import SvgMore from './../../base/icons/more.svg'
import SvgMoreHorisontal from './generated/MoreHorisontal.svg'
import SvgMoreVertical from './generated/MoreVertical.svg'
import SvgMoreVerticalCircle from './../../base/icons/more-vertical-circle.svg'
import SvgNavArchived from './../../base/icons/nav-archived.svg'
import SvgNavBackedUp from './../../base/icons/nav-backed-up.svg'
import SvgNavShared from './../../base/icons/nav-shared.svg'
import SvgNavSynced from './../../base/icons/nav-synced.svg'
import SvgNext from './../../base/icons/next.svg'
import SvgNoResults from './../../base/icons/no-results.svg'
import SvgNoSharedPhotos from './../../base/icons/no-shared-photos.svg'
import SvgNoWifi from './generated/NoWifi.svg'
import SvgOverlayFolderEmpty from './../../base/icons/overlay-folder-empty.svg'
import SvgOverlayFolderError from './../../base/icons/overlay-folder-error.svg'
import SvgOverlayUpload from './../../base/icons/overlay-upload.svg'
import SvgPause from './generated/Pause.svg'
import SvgPauseCircle from './generated/PauseCircle.svg'
import SvgPaypal from './generated/Paypal.svg'
import SvgPc from './generated/PC.svg'
import SvgPhoto from './generated/Photo.svg'
import SvgPhotoSearch from './../../base/icons/photo-search.svg'
import SvgPhotos from './generated/Photos.svg'
import SvgPhotosGray from './../../base/icons/photos-gray.svg'
import SvgPhotosUpload from './../../base/icons/photos-upload.svg'
import SvgPlay from './generated/Play.svg'
import SvgPlayCircle from './generated/PlayCircle.svg'
import SvgPlayCircleButton from './../../base/icons/play-circle-button.svg'
import SvgPlus from './generated/Plus.svg'
import SvgPlusCircle from './generated/PlusCircle.svg'
import SvgPlusSquare from './generated/PlusSquare.svg'
import SvgPoweredByJottacloud from './generated/Powered_by_Jottacloud.svg'
import SvgPoweredbyjotta from './../../base/icons/poweredbyjotta.svg'
import SvgPrevious from './../../base/icons/previous.svg'
import SvgPrinter from './generated/Printer.svg'
import SvgQuestion from './generated/Question.svg'
import SvgReply from './../../base/icons/reply.svg'
import SvgRestore from './../../base/icons/restore.svg'
import SvgScan from './generated/Scan.svg'
import SvgScreencast from './generated/Screencast.svg'
import SvgSearch from './generated/Search.svg'
import SvgSearchAlt from './../../base/icons/search-alt.svg'
import SvgSelect from './../../base/icons/select.svg'
import SvgSettings from './generated/Settings.svg'
import SvgShare from './../../base/icons/share.svg'
import SvgShareAndroid from './generated/ShareAndroid.svg'
import SvgShareIos from './generated/ShareIos.svg'
import SvgShared from './../../base/icons/shared.svg'
import SvgSidebar from './generated/Sidebar.svg'
import SvgSignOut from './generated/SignOut.svg'
import SvgSliders from './generated/Sliders.svg'
import SvgSlidersHorizontal from './generated/SlidersHorizontal.svg'
import SvgSmallArrowDown from './../../base/icons/small-arrow-down.svg'
import SvgSmiley from './generated/Smiley.svg'
import SvgSmileyWink from './generated/SmileyWink.svg'
import SvgSort from './../../base/icons/sort.svg'
import SvgSpace from './../../base/icons/space.svg'
import SvgSpinnerGap from './generated/SpinnerGap.svg'
import SvgSpreadsheet from './generated/Spreadsheet.svg'
import SvgSquare from './generated/Square.svg'
import SvgStar from './generated/Star.svg'
import SvgStarOutline from './../../base/icons/star-outline.svg'
import SvgStop from './../../base/icons/stop.svg'
import SvgSun from './generated/Sun.svg'
import SvgSync from './generated/Sync.svg'
import SvgTablet from './generated/Tablet.svg'
import SvgTalkToUs from './../../base/icons/talk-to-us.svg'
import SvgTerminalWindow from './generated/TerminalWindow.svg'
import SvgThumbAudio from './../../base/icons/thumb-audio.svg'
import SvgThumbDeviceCli from './../../base/icons/thumb-device-cli.svg'
import SvgThumbDeviceDesktop from './../../base/icons/thumb-device-desktop.svg'
import SvgThumbDeviceIpad from './../../base/icons/thumb-device-ipad.svg'
import SvgThumbDeviceIphone from './../../base/icons/thumb-device-iphone.svg'
import SvgThumbDeviceLaptop from './../../base/icons/thumb-device-laptop.svg'
import SvgThumbDocx from './../../base/icons/thumb-docx.svg'
import SvgThumbExcelMono from './../../base/icons/thumb-excel-mono.svg'
import SvgThumbExe from './../../base/icons/thumb-exe.svg'
import SvgThumbFile from './../../base/icons/thumb-file.svg'
import SvgThumbFolder from './../../base/icons/thumb-folder.svg'
import SvgThumbFolderHome from './../../base/icons/thumb-folder-home.svg'
import SvgThumbFolderPhotos from './../../base/icons/thumb-folder-photos.svg'
import SvgThumbFolderShared from './../../base/icons/thumb-folder-shared.svg'
import SvgThumbHtmlxml from './../../base/icons/thumb-htmlxml.svg'
import SvgThumbImage from './../../base/icons/thumb-image.svg'
import SvgThumbPdf from './../../base/icons/thumb-pdf.svg'
import SvgThumbPlainText from './../../base/icons/thumb-plainText.svg'
import SvgThumbPowerpointMono from './../../base/icons/thumb-powerpoint-mono.svg'
import SvgThumbPptx from './../../base/icons/thumb-pptx.svg'
import SvgThumbPresentation from './../../base/icons/thumb-presentation.svg'
import SvgThumbRichText from './../../base/icons/thumb-richText.svg'
import SvgThumbSpreadsheet from './../../base/icons/thumb-spreadsheet.svg'
import SvgThumbVideo from './../../base/icons/thumb-video.svg'
import SvgThumbWordMono from './../../base/icons/thumb-word-mono.svg'
import SvgThumbXlsx from './../../base/icons/thumb-xlsx.svg'
import SvgThumbZip from './../../base/icons/thumb-zip.svg'
import SvgTrash from './generated/Trash.svg'
import SvgUpload from './../../base/icons/upload.svg'
import SvgUploadSimple from './generated/UploadSimple.svg'
import SvgUser from './generated/User.svg'
import SvgUserCircle from './generated/UserCircle.svg'
import SvgUsersShared from './generated/UsersShared.svg'
import SvgUsersThree from './generated/UsersThree.svg'
import SvgVerified from './../../base/icons/verified.svg'
import SvgVersionHistory from './../../base/icons/version-history.svg'
import SvgVipps from './generated/Vipps.svg'
import SvgVippsMark from './../../base/icons/vipps_mark.svg'
import SvgVisa from './generated/Visa.svg'
import SvgWarning from './../../base/icons/warning.svg'
import SvgWarningCircle from './generated/WarningCircle.svg'
import SvgWifiHigh from './generated/WifiHigh.svg'
import SvgWindows from './../../base/icons/windows.svg'
import SvgWindowsRestore from './generated/WindowsRestore.svg'
import SvgX from './generated/X.svg'
import SvgXCircle from './generated/XCircle.svg'
import SvgXSquare from './generated/XSquare.svg'

export const icons: BrandThemeIconMap = {
  SvgAdd,
  SvgAlbumAdd,
  SvgAlbum,
  SvgAndroid,
  SvgAppleLogo,
  SvgBellAlert,
  SvgBiz,
  SvgBubbleOutline,
  SvgBubble,
  SvgBullhorn,
  SvgCannotMoveCopyCloud,
  SvgCast,
  SvgCheckcircleEmpty,
  SvgCheckcircleSelected,
  SvgCheckmarkIndeterminate,
  SvgCheckmark,
  SvgClose,
  SvgCloudError,
  SvgContact,
  SvgDownload,
  SvgEmoji,
  SvgEnvelop,
  SvgEraseSmall,
  SvgFacebookFLogo,
  SvgFamilySettings,
  SvgFileAdd,
  SvgFileMenu,
  SvgFilesApp,
  SvgFlagCa,
  SvgFlagDe,
  SvgFlagDk,
  SvgFlagEs,
  SvgFlagFi,
  SvgFlagFr,
  SvgFlagGb,
  SvgFlagNl,
  SvgFlagNo,
  SvgFlagPl,
  SvgFlagPt,
  SvgFlagSe,
  SvgFlagUs,
  SvgFolderLocked,
  SvgFolderMove,
  SvgFolderNew,
  SvgFolderSync,
  SvgFolderUpload,
  SvgFolder,
  SvgForwardIos,
  SvgGearWhite,
  SvgGeneric,
  SvgGoogleGLogo,
  SvgHelp,
  SvgHide,
  SvgIcCamera,
  SvgInstall,
  SvgLink,
  SvgLinux,
  SvgList,
  SvgLogout,
  SvgMessage,
  SvgMoreVerticalCircle,
  SvgMore,
  SvgNavArchived,
  SvgNavBackedUp,
  SvgNavShared,
  SvgNavSynced,
  SvgNext,
  SvgNoResults,
  SvgNoSharedPhotos,
  SvgOverlayFolderEmpty,
  SvgOverlayFolderError,
  SvgOverlayUpload,
  SvgPhotoSearch,
  SvgPhotosGray,
  SvgPhotosUpload,
  SvgPlayCircleButton,
  SvgPoweredbyjotta,
  SvgPrevious,
  SvgReply,
  SvgRestore,
  SvgSearchAlt,
  SvgSelect,
  SvgShare,
  SvgShared,
  SvgSmallArrowDown,
  SvgSort,
  SvgSpace,
  SvgStarOutline,
  SvgStop,
  SvgTalkToUs,
  SvgThumbAudio,
  SvgThumbDeviceCli,
  SvgThumbDeviceDesktop,
  SvgThumbDeviceIpad,
  SvgThumbDeviceIphone,
  SvgThumbDeviceLaptop,
  SvgThumbDocx,
  SvgThumbExcelMono,
  SvgThumbExe,
  SvgThumbFile,
  SvgThumbFolderHome,
  SvgThumbFolderPhotos,
  SvgThumbFolderShared,
  SvgThumbFolder,
  SvgThumbHtmlxml,
  SvgThumbImage,
  SvgThumbPdf,
  SvgThumbPlainText,
  SvgThumbPowerpointMono,
  SvgThumbPptx,
  SvgThumbPresentation,
  SvgThumbRichText,
  SvgThumbSpreadsheet,
  SvgThumbVideo,
  SvgThumbWordMono,
  SvgThumbXlsx,
  SvgThumbZip,
  SvgUpload,
  SvgVerified,
  SvgVersionHistory,
  SvgVippsMark,
  SvgWarning,
  SvgWindows,
  SvgAddPhoto,
  SvgAddFile,
  SvgAddFolder,
  SvgApple,
  SvgArchive,
  SvgArrowClockwise,
  SvgArrowCounterClockwise,
  SvgArrowDown,
  SvgArrowLeft,
  SvgArrowRight,
  SvgArrowUp,
  SvgArrowsDownUp,
  SvgAttachment,
  SvgBackup,
  SvgBell,
  SvgBellRinging,
  SvgBoldAddFile,
  SvgBoldAddFolder,
  SvgBoldArchive,
  SvgBoldArrowClockwise,
  SvgBoldArrowCounterClockwise,
  SvgBoldArrowDown,
  SvgBoldArrowLeft,
  SvgBoldArrowRight,
  SvgBoldArrowUp,
  SvgBoldArrowsDownUp,
  SvgBoldAttachment,
  SvgBoldBackup,
  SvgBoldBell,
  SvgBoldBellRinging,
  SvgBoldCamera,
  SvgBoldCaretCircleDown,
  SvgBoldCaretCircleLeft,
  SvgBoldCaretCircleRight,
  SvgBoldCaretCircleUp,
  SvgBoldCaretCircleUpDown,
  SvgBoldCaretDown,
  SvgBoldCaretLeft,
  SvgBoldCaretRight,
  SvgBoldCaretUp,
  SvgBoldCaretUpDown,
  SvgBoldChatCircleDots,
  SvgBoldCheck,
  SvgBoldCheckCircle,
  SvgBoldCircle,
  SvgBoldCircleNotch,
  SvgBoldClock,
  SvgBoldClockCounterClockwise,
  SvgBoldCloud,
  SvgBoldCloudCheck,
  SvgBoldCloudDownload,
  SvgBoldCloudUpload,
  SvgBoldCloudWarning,
  SvgBoldCopy,
  SvgBoldCreditCard,
  SvgBoldDesktop,
  SvgBoldDevice,
  SvgBoldDownloadSimple,
  SvgBoldEdit,
  SvgBoldExitFullscreen,
  SvgBoldExpandFullscreen,
  SvgBoldExternal,
  SvgBoldEye,
  SvgBoldEyeSlash,
  SvgBoldFile,
  SvgBoldFileArchive,
  SvgBoldFileAudio,
  SvgBoldFileCode,
  SvgBoldFileCss,
  SvgBoldFileDoc,
  SvgBoldFileDownload,
  SvgBoldFileHtml,
  SvgBoldFileJpg,
  SvgBoldFilePdf,
  SvgBoldFilePng,
  SvgBoldFilePpt,
  SvgBoldFileSvg,
  SvgBoldFileText,
  SvgBoldFileUpload,
  SvgBoldFileVideo,
  SvgBoldFileXls,
  SvgBoldFileZip,
  SvgBoldFiles,
  SvgBoldFolderOpen,
  SvgBoldFolderSimple,
  SvgBoldFolders,
  SvgBoldFunnel,
  SvgBoldGlobeSimple,
  SvgBoldGrid,
  SvgBoldGrid2,
  SvgBoldHome,
  SvgBoldImageSquare,
  SvgBoldInfinity,
  SvgBoldInfo,
  SvgBoldLaptop,
  SvgBoldLinkSimple,
  SvgBoldList,
  SvgBoldListDashes,
  SvgBoldLockKey,
  SvgBoldMagicWand,
  SvgBoldMapPin,
  SvgBoldMapPinLine,
  SvgBoldMemories,
  SvgBoldMinus,
  SvgBoldMinusCircle,
  SvgBoldMinusSquare,
  SvgBoldMobile,
  SvgBoldMoonStars,
  SvgBoldMoreHorisontal,
  SvgBoldMoreVertical,
  SvgBoldPc,
  SvgBoldPause,
  SvgBoldPauseCircle,
  SvgBoldPhoto,
  SvgBoldPhotos,
  SvgBoldPlay,
  SvgBoldPlayCircle,
  SvgBoldPlus,
  SvgBoldPlusCircle,
  SvgBoldPlusSquare,
  SvgBoldPrinter,
  SvgBoldQuestion,
  SvgBoldScan,
  SvgBoldScreencast,
  SvgBoldSearch,
  SvgBoldSettings,
  SvgBoldShareAndroid,
  SvgBoldShareIos,
  SvgBoldSidebar,
  SvgBoldSignOut,
  SvgBoldSliders,
  SvgBoldSlidersHorizontal,
  SvgBoldSmiley,
  SvgBoldSmileyWink,
  SvgBoldSpinnerGap,
  SvgBoldSpreadsheet,
  SvgBoldSquare,
  SvgBoldStar,
  SvgBoldSun,
  SvgBoldSync,
  SvgBoldTablet,
  SvgBoldTerminalWindow,
  SvgBoldTrash,
  SvgBoldUploadSimple,
  SvgBoldUser,
  SvgBoldUserCircle,
  SvgBoldUsersShared,
  SvgBoldUsersThree,
  SvgBoldWarningCircle,
  SvgBoldWifiHigh,
  SvgBoldWifiSlash,
  SvgBoldWindowsRestore,
  SvgBoldX,
  SvgBoldXCircle,
  SvgBoldXSquare,
  SvgBrandElkjop,
  SvgBrandGeneric,
  SvgBrandJottacloud,
  SvgBrandMediamarkt,
  SvgBrandShared,
  SvgBrandTelia,
  SvgCamera,
  SvgCaretCircleDown,
  SvgCaretCircleLeft,
  SvgCaretCircleRight,
  SvgCaretCircleUp,
  SvgCaretCircleUpDown,
  SvgCaretDown,
  SvgCaretLeft,
  SvgCaretRight,
  SvgCaretUpDown,
  SvgChatCircleDots,
  SvgCheck,
  SvgCheckCircle,
  SvgCircle,
  SvgCircleNotch,
  SvgClock,
  SvgClockCounterClockwise,
  SvgCloud,
  SvgCloudArrowDown,
  SvgCloudArrowUp,
  SvgCloudCheck,
  SvgCloudWarning,
  SvgControl,
  SvgCopy,
  SvgCreditCard,
  SvgDesktop,
  SvgDevice,
  SvgDownloadSimple,
  SvgEdit,
  SvgExitFullscreen,
  SvgExpandFullscreen,
  SvgExternal,
  SvgEye,
  SvgEyeSlash,
  SvgFacebook,
  SvgFile,
  SvgFileArchive,
  SvgFileAudio,
  SvgFileCode,
  SvgFileCss,
  SvgFileDoc,
  SvgFileDownload,
  SvgFileHtml,
  SvgFileJpg,
  SvgFilePdf,
  SvgFilePng,
  SvgFilePpt,
  SvgFileSvg,
  SvgFileText,
  SvgFileUpload,
  SvgFileVideo,
  SvgFileXls,
  SvgFileZip,
  SvgFiles,
  SvgFillAddFile,
  SvgFillAddFolder,
  SvgFillArchive,
  SvgFillArrowClockwise,
  SvgFillArrowCounterClockwise,
  SvgFillArrowDown,
  SvgFillArrowLeft,
  SvgFillArrowRight,
  SvgFillArrowUp,
  SvgFillArrowsDownUp,
  SvgFillAttachment,
  SvgFillBackup,
  SvgFillBell,
  SvgFillBellRinging,
  SvgFillCamera,
  SvgFillCaretCircleDown,
  SvgFillCaretCircleLeft,
  SvgFillCaretCircleRight,
  SvgFillCaretCircleUp,
  SvgFillCaretCircleUpDown,
  SvgFillCaretDown,
  SvgFillCaretLeft,
  SvgFillCaretRight,
  SvgFillCaretUp,
  SvgFillCaretUpDown,
  SvgFillChatCircleDots,
  SvgFillCheck,
  SvgFillCheckCircle,
  SvgFillCircle,
  SvgFillCircleNotch,
  SvgFillClock,
  SvgFillClockCounterClockwise,
  SvgFillCloud,
  SvgFillCloudCheck,
  SvgFillCloudDownload,
  SvgFillCloudUpload,
  SvgFillCloudWarning,
  SvgFillCopy,
  SvgFillCreditCard,
  SvgFillDesktop,
  SvgFillDevice,
  SvgFillDownloadSimple,
  SvgFillEdit,
  SvgFillExitFullscreen,
  SvgFillExpandFullscreen,
  SvgFillExternal,
  SvgFillEye,
  SvgFillEyeSlash,
  SvgFillFile,
  SvgFillFileArchive,
  SvgFillFileAudio,
  SvgFillFileCode,
  SvgFillFileCss,
  SvgFillFileDoc,
  SvgFillFileDownload,
  SvgFillFileHtml,
  SvgFillFileJpg,
  SvgFillFilePdf,
  SvgFillFilePng,
  SvgFillFilePpt,
  SvgFillFileSvg,
  SvgFillFileText,
  SvgFillFileUpload,
  SvgFillFileVideo,
  SvgFillFileXls,
  SvgFillFileZip,
  SvgFillFiles,
  SvgFillFolderOpen,
  SvgFillFolderSimple,
  SvgFillFolders,
  SvgFillFunnel,
  SvgFillGrid,
  SvgFillGrid2,
  SvgFillHome,
  SvgFillImageSquare,
  SvgFillInfinity,
  SvgFillInfo,
  SvgFillInternetWeb,
  SvgFillLaptop,
  SvgFillLinkSimple,
  SvgFillListDashes,
  SvgFillLockKey,
  SvgFillMagicWand,
  SvgFillMapPin,
  SvgFillMapPinLine,
  SvgFillMemories,
  SvgFillMenu,
  SvgFillMinus,
  SvgFillMinusCircle,
  SvgFillMinusSquare,
  SvgFillMobile,
  SvgFillMoonStars,
  SvgFillMoreHorisontal,
  SvgFillMoreVertical,
  SvgFillNoWifi,
  SvgFillPc,
  SvgFillPause,
  SvgFillPauseCircle,
  SvgFillPhoto,
  SvgFillPhotos,
  SvgFillPlay,
  SvgFillPlayCircle,
  SvgFillPlus,
  SvgFillPlusCircle,
  SvgFillPlusSquare,
  SvgFillPrinter,
  SvgFillQuestion,
  SvgFillScan,
  SvgFillScreencast,
  SvgFillSearch,
  SvgFillSettings,
  SvgFillShareAndroid,
  SvgFillShareIos,
  SvgFillSidebar,
  SvgFillSignOut,
  SvgFillSliders,
  SvgFillSlidersHorizontal,
  SvgFillSmiley,
  SvgFillSmileyWink,
  SvgFillSpinnerGap,
  SvgFillSpreadsheet,
  SvgFillSquare,
  SvgFillStar,
  SvgFillSun,
  SvgFillSync,
  SvgFillTablet,
  SvgFillTerminalWindow,
  SvgFillTrash,
  SvgFillUploadSimple,
  SvgFillUser,
  SvgFillUserCircle,
  SvgFillUsersShared,
  SvgFillUsersThree,
  SvgFillWarningCircle,
  SvgFillWifiHigh,
  SvgFillWindowsRestore,
  SvgFillX,
  SvgFillXCircle,
  SvgFillXSquare,
  SvgFolderOpen,
  SvgFolderSimple,
  SvgFolders,
  SvgFunnel,
  SvgGoogle,
  SvgGrid,
  SvgGrid2,
  SvgHome,
  SvgImageSquare,
  SvgInfinity,
  SvgInfo,
  SvgInternetWeb,
  SvgLaptop,
  SvgLinkSimple,
  SvgListDashes,
  SvgLockKey,
  SvgLogoFavIcon,
  SvgLogoHeader,
  SvgLogoIcon,
  SvgLogoMain,
  SvgMagicWand,
  SvgMapPin,
  SvgMapPinLine,
  SvgMastercard,
  SvgMemories,
  SvgMenu,
  SvgMinus,
  SvgMinusCircle,
  SvgMinusSquare,
  SvgMobile,
  SvgMoonStars,
  SvgMoreHorisontal,
  SvgMoreVertical,
  SvgNoWifi,
  SvgPc,
  SvgPause,
  SvgPauseCircle,
  SvgPaypal,
  SvgPhoto,
  SvgPhotos,
  SvgPlay,
  SvgPlayCircle,
  SvgPlus,
  SvgPlusCircle,
  SvgPlusSquare,
  SvgPoweredByJottacloud,
  SvgPrinter,
  SvgQuestion,
  SvgScan,
  SvgScreencast,
  SvgSearch,
  SvgSettings,
  SvgShareAndroid,
  SvgShareIos,
  SvgSidebar,
  SvgSignOut,
  SvgSliders,
  SvgSlidersHorizontal,
  SvgSmiley,
  SvgSmileyWink,
  SvgSpinnerGap,
  SvgSpreadsheet,
  SvgSquare,
  SvgStar,
  SvgSun,
  SvgSync,
  SvgTablet,
  SvgTerminalWindow,
  SvgTrash,
  SvgUploadSimple,
  SvgUser,
  SvgUserCircle,
  SvgUsersShared,
  SvgUsersThree,
  SvgVipps,
  SvgVisa,
  SvgWarningCircle,
  SvgWifiHigh,
  SvgWindowsRestore,
  SvgX,
  SvgXCircle,
  SvgXSquare,
}
